import { responsiveFontSizes } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import palette from './palette';
import shadows from './shadows';

const getTheme = () =>
  responsiveFontSizes(
    createTheme({
      palette: palette,
      layout: {
        contentWidth: 1236,
      },
      shadows: shadows,
      typography: {
        h1: {fontFamily: '"Permanent Marker", cursive'},
        h2: {fontFamily: '"Permanent Marker", cursive'},
        h3: {fontFamily: '"Permanent Marker", cursive'},
        h4: {fontFamily: '"Permanent Marker", cursive'},
        fontFamily: '"Itim", sans-serif',
        button: {
          textTransform: 'none',
          fontWeight: 'medium',
        },
      },
      zIndex: {
        appBar: 1200,
        drawer: 1300,
      },
      components: {
        MuiButton: {
          styleOverrides: {
            label: {
              fontWeight: 600,
            },
            containedSecondary: {},
          },
        },
      },
    }),
  );

export default getTheme;