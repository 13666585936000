import getTheme from './theme';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import WolfBoysLogo from './components/WolfBoysLogo';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import Marquee from "react-fast-marquee";
import ListItemText from '@mui/material/ListItemText';
import Link from '@mui/material/Link';
import SocialMediaBar from './components/SocialMediaBar';
import FAQ from './components/FAQ';
import WoofDialog from './components/WoofDialog';

function App() {
  const theme = getTheme();

  return (
    <div>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Container maxWidth="lg">

          {/* TOPBAR */}
          <header>
            <AppBar
              position={'static'}
              sx={{
                background: theme.palette.common.black,
                marginBottom: 2,
                marginTop: 2
              }}
            >
              <Container paddingY={{ xs: 1 / 2, sm: 1 }}>
                <Box
                  display={'flex'}
                  justifyContent={'space-between'}
                  alignItems={'center'}
                  width={'100%'}
                >
                  <Box display={'flex'} alignItems={'center'}>
                    <Box
                      display={'flex'}
                      alignItems="baseline"
                      component="a"
                      underline="none"
                      href="/"
                      title="WOLFBOYS"
                      height={{ xs: 48, md: 58 }}
                      width={45}
                    >
                      <WolfBoysLogo height={'100%'} width={'100%'} />
                    </Box>
                  </Box>
                  <Box display="flex" alignItems={'center'}>
                    <Box sx={{ display: { xs: 'flex', md: 'flex' } }} alignItems={'center'}>
                      <Box>
                        {/* <Button href="#" variant="contained" size="large" color="primary"
                         style={{backgroundImage: 'linear-gradient(90deg,#29303a 0,#617785)'}}
                        >
                          CONNECT WALLET
                        </Button> */}
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Container>
            </AppBar>
          </header>

          {/* HERO */}
          <Container paddingY={{ xs: 1 / 2, sm: 1 }}>
            <Card sx={{borderRadius: '15px', borderColor: '#000000'}}>
              <CardMedia
                component="img"
                // sx={{ display: { xs: 'none', sm: 'block' } }}
                sx={{ display: { sm: 'block' } }}
                image={'/assets/banner1.jpg'}
                alt="WOLF BOYS"
                height={'100%'}
              />
            </Card>
          </Container>

          <br /><br />

          {/* PURCHASE */}
          <Container>
            <Card sx={{borderRadius: '15px', borderColor: '#000000'}} style={{backgroundImage: 'linear-gradient(90deg,#1d5586 0,#b2e5ed)'}}>
              <CardContent>
                <Box display={{ xs: 'block', sm: 'none' }}>
                  <Typography
                    variant={'h4'}
                    component={'p'}
                    color={'textPrimary'}
                    align={'center'}
                    marginBottom={2}
                  >
                    JOIN THE CLUB
                  </Typography>
                  <Box textAlign="center">
                  <Typography
                        display="inline"
                        variant={'h6'}
                        component={'p'}
                        color={'textPrimary'}
                        align={'left'}
                      >
                        STEALTH DROP
                      </Typography>
                      <Typography
                        marginLeft={1}
                        marginRight={1}
                        display="inline"
                        variant={'h6'}
                        component={'p'}
                        color={'hsla(0,0%,100%,.2)'}
                        align={'left'}
                      >
                        |
                      </Typography>
                      <Typography
                        display="inline"
                        variant={'h6'}
                        component={'p'}
                        color={'textPrimary'}
                        align={'left'}
                      >
                        IMX
                      </Typography>
                      <Typography
                        marginLeft={1}
                        marginRight={1}
                        display="inline"
                        variant={'h6'}
                        component={'p'}
                        color={'hsla(0,0%,100%,.2)'}
                        align={'left'}
                      >
                        |
                      </Typography>
                      <Typography
                        display="inline"
                        variant={'h6'}
                        component={'p'}
                        color={'textPrimary'}
                        align={'left'}
                      >
                        GAS FREE
                      </Typography>
                      <Typography
                        marginLeft={1}
                        marginRight={1}
                        display="inline"
                        variant={'h6'}
                        component={'p'}
                        color={'hsla(0,0%,100%,.2)'}
                        align={'center'}
                      >
                        |
                      </Typography>
                      <Typography
                        display="inline"
                        variant={'h6'}
                        component={'p'}
                        color={'textPrimary'}
                        align={'center'}
                      >
                        0.005 ETH
                      </Typography>
                    <br /><br />
                    <ButtonGroup variant="contained" size={'xlg'}>
                        <Button href="https://market.immutable.com/assets?collection=0xd3fc45dbb5ebb6aab7245823720a972d98e7f546" target="_blank" variant="contained" size="lg" color="primary">
                          BUY NOW
                        </Button>
                      </ButtonGroup>
                  </Box>
                </Box>
                <Box display={{ xs: 'none', sm: 'block' }}>
                  <Typography
                      variant={'h4'}
                      component={'p'}
                      color={'textPrimary'}
                      align={'left'}
                      marginBottom={1}
                    >
                      JOIN THE CLUB
                  </Typography>
                  <Grid container spacing={1} marginTop={1}>
                    <Grid item xs={6}>
                    <Typography
                        display="inline"
                        variant={'h6'}
                        component={'p'}
                        color={'textPrimary'}
                        align={'left'}
                      >
                        STEALTH DROP
                      </Typography>
                      <Typography
                        marginLeft={1}
                        marginRight={1}
                        display="inline"
                        variant={'h6'}
                        component={'p'}
                        color={'hsla(0,0%,100%,.2)'}
                        align={'left'}
                      >
                        |
                      </Typography>
                      <Typography
                        display="inline"
                        variant={'h6'}
                        component={'p'}
                        color={'textPrimary'}
                        align={'left'}
                      >
                        IMX
                      </Typography>
                      <Typography
                        marginLeft={1}
                        marginRight={1}
                        display="inline"
                        variant={'h6'}
                        component={'p'}
                        color={'hsla(0,0%,100%,.2)'}
                        align={'left'}
                      >
                        |
                      </Typography>
                      <Typography
                        display="inline"
                        variant={'h6'}
                        component={'p'}
                        color={'textPrimary'}
                        align={'left'}
                      >
                        GAS FREE
                      </Typography>
                      <Typography
                        marginLeft={1}
                        marginRight={1}
                        display="inline"
                        variant={'h6'}
                        component={'p'}
                        color={'hsla(0,0%,100%,.2)'}
                        align={'center'}
                      >
                        |
                      </Typography>
                      <Typography
                        display="inline"
                        variant={'h6'}
                        component={'p'}
                        color={'textPrimary'}
                        align={'center'}
                      >
                        0.005 ETH
                      </Typography>
                    </Grid>
                    <Grid item xs={6} align={'right'}>
                      <ButtonGroup variant="contained" size={'xlg'}>
                        <Button href="https://market.immutable.com/assets?collection=0xd3fc45dbb5ebb6aab7245823720a972d98e7f546" target="_blank" variant="contained" size="lg" color="primary">
                          BUY NOW
                        </Button>
                      </ButtonGroup>
                    </Grid>
                  </Grid>
                </Box>
                  {/* <hr style={{backgroundColor: 'hsla(0,0%,100%,.1)', height: '1px', border: 'none'}} /> */}
              </CardContent>
            </Card>
          </Container>

          <br />

          {/* MAIN */}
          <Container>
            <Card sx={{borderRadius: '15px', borderColor: '#000000'}}>
              <CardContent>
                <Box textAlign='center' marginLeft={5} marginRight={5} marginTop={2}>
                  <Typography
                    variant={'h6'}
                    component={'p'}
                    color={'textSecondary'}
                    align={'center'}
                    marginBottom={2}
                  >
                     Sly Wolf Racing Club is a collection of 10,000 meticulously HAND DRAWN, algorithmically-generated,
                    NFT digital identities with an illustrative ink &amp; watercolor look.
                    Each is randomly generated from more than 500 traits across 10 categories and
                    4 rarity tiers: common, uncommon, rare, and epic.
                  </Typography>
                  <SocialMediaBar />
                </Box>
              </CardContent>
            </Card>
          </Container>
        </Container>

        <br /><br />

        {/* MARQUEE */}
        <Marquee gradient={false} speed={30}>
          <img src="/assets/lower_res/1.png" ></img>
          <img src="/assets/lower_res/2.png" ></img>
          <img src="/assets/lower_res/3.png" ></img>
          <img src="/assets/lower_res/4.png" ></img>
          <img src="/assets/lower_res/5.png" ></img>
          <img src="/assets/lower_res/6.png" ></img>
          <img src="/assets/lower_res/7.png" ></img>
          <img src="/assets/lower_res/8.png" ></img>
          <img src="/assets/lower_res/9.png" ></img>
          <img src="/assets/lower_res/10.png" ></img>
          <img src="/assets/lower_res/11.png" ></img>
          <img src="/assets/lower_res/12.png" ></img>
          <img src="/assets/lower_res/13.png" ></img>
          <img src="/assets/lower_res/14.png" ></img>
          <img src="/assets/lower_res/15.png" ></img>
          <img src="/assets/lower_res/16.png" ></img>
          <img src="/assets/lower_res/17.png" ></img>
          <img src="/assets/lower_res/18.png" ></img>
          <img src="/assets/lower_res/19.png" ></img>
          <img src="/assets/lower_res/20.png" ></img>
          <img src="/assets/lower_res/21.png" ></img>
        </Marquee>
        <br />
        {/* STORY */}
        <Container maxWidth="lg">
          <br />
          <Container>
            <Card sx={{borderRadius: '15px', borderColor: '#000000', background: '#000'}}>
              <CardContent>
                <Box textAlign='center' marginLeft={5} marginRight={5} marginTop={3} >
                  <Typography
                    variant={'h3'}
                    component={'p'}
                    color={'textPrimary'}
                    align={'center'}
                    marginBottom={1}
                  >
                    STORY
                  </Typography>
                  <Typography
                    variant={'h6'}
                    component={'p'}
                    color={'textSecondary'}
                    align={'center'}
                    marginBottom={3}
                  >
                    Long ago in the 21st century, a primitive race known as the &quot;hoomans&quot; were wiped out.
                    However, our canine ancestors survived the calamity and evolved.
                    Thousands of moons later, we have uncovered the ancient relics of the hooman civilization,
                    and with them, will usher in a new era of the wolf.
                  </Typography>
                </Box>
              </CardContent>
            </Card>
          </Container>
          {/* <hr style={{backgroundColor: 'hsla(0,0%,100%,.1)', height: '1px', border: 'none'}} /> */}

          {/* FEATURES */}
          <Container>
            <Card sx={{borderRadius: '15px', borderColor: '#000000', background: '#000'}}>
              <CardContent>
                <Box textAlign='center' marginLeft={5} marginRight={5} marginTop={5}>
                  <Typography
                    variant={'h3'}
                    component={'p'}
                    color={'textPrimary'}
                    align={'center'}
                    marginBottom={1}
                  >
                    FEATURES
                  </Typography>
                </Box>
              </CardContent>
            </Card>
          </Container>
          <Container>
            <Card sx={{ display: 'flex', borderRadius: '15px', borderColor: '#000000', alignItems: 'center' }}>
              <Box display={{ xs: 'none', sm: 'block' }}>
                <CardMedia
                  component="img"
                  sx={{ width: 230, height: 230, borderRadius: '15px', borderColor: '#000000' }}
                  image="/assets/feature1.png"
                />
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Box display={{ xs: 'block', sm: 'none' }}>
                  <CardMedia
                    component="img"
                    sx={{ width: '100%', height: 350, borderRadius: '15px', borderColor: '#000000' }}
                    image="/assets/feature1.png"
                  />
                </Box>
                <CardContent sx={{ flex: '1 0 auto' }}>
                  <Typography component="div" variant="h4" marginBottom={2} textAlign="center">
                    Ink &amp; Watercolor Style
                  </Typography>
                  <Typography variant="h6" color="textSecondary" component="div" textAlign="center">
                    Carefully crafted and inspired by the fantasy art we grew up on, every Sly Wolf is a labor of love with their own attitude and charm.
                  </Typography>
                </CardContent>
              </Box>
            </Card>


          </Container>
          <br /><br /><br />
          {/* <hr style={{backgroundColor: 'hsla(0,0%,100%,.1)', height: '1px', border: 'none'}} /> */}

          {/* <br /> */}
          {/* <hr style={{backgroundColor: 'hsla(0,0%,100%,.1)', height: '1px', border: 'none'}} /> */}
          {/* FAQ */}
          {/* <Container>
            <Card sx={{borderRadius: '15px', borderColor: '#000000', background: '#000'}}>
              <CardContent>
                <Box textAlign='center' marginLeft={5} marginRight={5} marginTop={5}>
                  <Typography
                    variant={'h3'}
                    component={'p'}
                    color={'textPrimary'}
                    align={'center'}
                    marginBottom={1}
                  >
                    FAQ
                  </Typography>
                </Box>
              </CardContent>
            </Card>
          </Container>
          <FAQ /> */}
          {/* TEAM */}
          <Container>
            <Card sx={{borderRadius: '15px', borderColor: '#000000', background: '#000'}}>
              <CardContent>
                <Box textAlign='center' marginLeft={5} marginRight={5} marginTop={3}>
                  <Typography
                    variant={'h3'}
                    component={'p'}
                    color={'textPrimary'}
                    align={'center'}
                    marginBottom={1}
                  >
                    TEAM
                  </Typography>
                </Box>
              </CardContent>
            </Card>
          </Container>
          <Container>
            <Grid container spacing={6} alignItems="center" justify="center" display="flex">
              {[
                {
                  name: 'Warlock',
                  title: 'Artwork',
                  avatar: '/assets/lower_res/warlock.png',
                },
                {
                  name: 'Beastman',
                  title: 'Development',
                  avatar: '/assets/lower_res/beastman.png',
                  twitter: 'https://twitter.com/ragingbeastman'
                }
              ].map((item, i) => (
                <Grid item xs={24} sm={12} md={6} key={i}>
                  <Box component={Card} boxShadow={0} bgcolor={'transparent'} align={'center'}>
                    <Box
                      component={CardMedia}
                      borderRadius={2}
                      width={'60%'}
                      height={'100%'}
                      minHeight={250}
                      image={item.avatar}
                    />
                    <Box
                      component={CardContent}
                      bgcolor={'transparent'}
                      marginTop={-5}
                    >
                      <Box component={Card} borderRadius={2}>
                        <CardContent>
                          <Typography component="div" variant="h4" marginBottom={2} textAlign="center">
                            {item.name}
                          </Typography>
                          <ListItemText primary={item.title} />
                        </CardContent>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Container>

          {/* FOOTER */}
          <footer>
            <Box textAlign='center' marginTop={10}>
              <SocialMediaBar />
              <Typography
                variant={'h6'}
                component={'p'}
                color={'textSecondary'}
                align={'center'}
                marginBottom={3}
              >
                &copy; 2021 Sly Wolf Racing Club
              </Typography>
            </Box>
          </footer>
        </Container>
      </ThemeProvider>
    </div>
  );
}

export default App;
