import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';

const WolfBoysLogo = ({ width = 45, height = 70 }) => {
  const theme = useTheme();
  // const colorPrimaryMain = theme.palette.primary.main;
  const colorPrimaryMain = '#FFFFFF';
  const colorPrimaryDark = theme.palette.primary.main;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 504.28 504.18"
    >
      <polygon
        points="105.416,171.798 168.531,274.985 231.645,171.798 231.645,171.493 168.531,68.307 105.416,171.493"
        fill={colorPrimaryMain}
        stroke={colorPrimaryDark}
      ></polygon>
      <polygon
        points="11.817,324.583 74.932,427.77 138.046,324.583 138.046,324.277 74.932,221.092 11.817,324.277"
        fill={colorPrimaryMain}
        stroke={colorPrimaryDark}
      ></polygon>
      <polygon
        points="365.197,431.802 136.574,431.802 136.574,431.609 250.887,244.718 365.197,431.609"
        fill={colorPrimaryMain}
        stroke={colorPrimaryDark}
      ></polygon>
      <polygon
        points="398.14,171.798 335.026,274.985 271.911,171.798 271.911,171.493 335.026,68.307 398.14,171.493"
        fill={colorPrimaryMain}
        stroke={colorPrimaryDark}
      ></polygon>
      <polygon
        points="491.739,324.583 428.624,427.77 365.511,324.583 365.511,324.277 428.624,221.092 491.739,324.277"
        fill={colorPrimaryMain}
        stroke={colorPrimaryDark}
      ></polygon>
    </svg>
  );
};

WolfBoysLogo.propTypes = {
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default WolfBoysLogo;
