import React from 'react';
import Container from '@mui/material/Container';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

const data = {
  rows: [
    {
      q: "HOW MANY TRAITS ARE THERE?",
      a: `Sly Wolf Racing Club collectibles are generated from more than 200 traits across 10 categories and 4 rarity tiers: common, uncommon, rare, and epic.`,
    },
    {
      q: "HOW DO I JOIN THE WHITELIST?",
      a: `Be active on our Discord. We will post official announcements on how to sign up.`,
    },
    {
      q: "HOW CAN I MINT A SLY WOLF?",
      a: `Connect your account via Metamask or WalletConnect to pay the mint and gas fee.`,
    },
    {
      q: "HOW MUCH IS IT TO MINT A SLY WOLF?",
      a: `0.03 ETH`,
    },
    {
      q: "WHERE IS THE DATA STORED?",
      a: `Name and bio is stored on-chain. Avatars are distributed to IPFS`,
    },
  ],
};

const useStyles = makeStyles((theme) => ({
  customAccordionSummary: {
    justifyContent: "center"
  }
}));

const FAQ = () => {
  const theme = useTheme();
  const classes = useStyles();

  return (
    <Container>
      { data.rows.map((item, i) => (
        <Accordion sx={{borderColor: '#000000', background: '#000', justifyContent: 'center'}} align="center">
          <AccordionSummary
            justify-content="center"
            sx={{justifyContent: 'center'}}
            expandIcon={<ExpandMoreIcon />}
            classes={{ content: classes.customAccordionSummary }}
          >
            <Typography component="div" variant="h4">{item.q}</Typography>
          </AccordionSummary>
          <AccordionDetails sx={{justifyContent: "center"}}>
            <Typography component="div" variant="h6" color="textSecondary">
              {item.a}
            </Typography>
          </AccordionDetails>
        </Accordion>
      ))}
    </Container>
  );
}

export default FAQ;