import React from 'react';
import Box from '@mui/material/Box';
import Icon from '@mui/material/Icon';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import { ReactComponent as TwitterSVG } from './svg/twitter-icon.svg';
import { ReactComponent as DiscordSVG } from './svg/discord-icon.svg';
import { ReactComponent as OpenseaSVG } from './svg/opensea-icon.svg';
import { ReactComponent as EtherscanSVG } from './svg/etherscan-icon.svg';

const SocialMediaBar = () => {
  return (
    <Box textAlign='center' marginTop={1}>
      <Link href="https://twitter.com/SlyWolfRC" target="_blank" >
        <IconButton size="large">
          <Icon fontSize="large">
            <TwitterSVG width={36} height={36} />
          </Icon>
        </IconButton>
      </Link>
      <Link href="https://discord.gg/mNUfkpybsh" target="_blank" >
        <IconButton size="large" variant="contained">
          <Icon fontSize="large">
            <DiscordSVG width={36} height={36} />
          </Icon>
        </IconButton>
      </Link>
      {/* <Link href="https://opensea.io/collection/sly-wolf-racing-club" target="_blank" >
        <IconButton size="large" variant="contained">
          <Icon fontSize="large">
            <OpenseaSVG width={36} height={36} />
          </Icon>
        </IconButton>
      </Link> */}
      <Link href="https://etherscan.io/address/0xD3FC45Dbb5Ebb6aAB7245823720a972d98E7f546" target="_blank" >
        <IconButton size="large" variant="contained">
          <Icon fontSize="large">
            <EtherscanSVG width={36} height={36} />
          </Icon>
        </IconButton>
      </Link>
    </Box>
  )
}

export default SocialMediaBar;